import {
  CompanyAccountOutput,
  CompanyRole,
  CompanySubscriptionPlan,
  CompanySuspensionStatus,
  FeedParams,
  OperatingCountry,
  StripeRegion,
  SubscriptionPlanConfiguration,
  SubscriptionPlanInterface,
  FeedParam
} from '@cohiretech/common-types';

import * as consts from 'consts';
import { getMillisecondsByType, isEmpty } from 'utils';
import { trackCompaniesHubLinkClicks } from 'tracking-utils';
import { companySearchFilters, companySortByOptions, setCompanyTracking } from 'fetcher';
import { getLocalStorageItem, setLocalStorageItem } from 'cookieManager';
import { isAgencyProfile, isCompanyProfile, ProfileState } from 'store';
import {
  CompanyProfile,
  PositionInProfile,
  Permissions,
  SubscriptionPlan,
  NewPricingOptions,
  PricingPlanDetails,
  UsageStatistics,
  V3PricingOptions,
  AgencySubscriptionPlans,
  PayPerHirePricingOptions
} from 'types';
import { UNLIMITED_PLANS } from 'consts';
import { isNewPricingPlanV5, isPayPerHirePricingPlan } from 'v2/services/tools/pricing';

import PricingPlansV4 from 'components/pricingplansv4';
import PricingPlansV3 from 'components/pricingplansv3';
import NewPricingPlans from 'components/pricingplansnew';
import PricingPlans from 'components/pricingplans';

import Pricing, { PricingProps } from 'v2/components/ui/templates/Pricing';

// @ts-expect-error TS(2614) FIXME: Module '"*.module.scss"' has no exported member 'a... Remove this comment to see the full error message
import { activeDarkShade, errorColour, warningColour } from 'css/base/_colours.module.scss';

import { getCurrentPlan, getV3PricingOptions, getV4PricingOptions } from './pricingUtils';
import { getLocalisedDefaultSearchItems } from './localisationUtils';

export const defaultSearchItems: FeedParam[] = [
  {
    attribute: companySearchFilters.sortBy.attribute,
    label: companySortByOptions[3].label, // should default to "Recommended" sorting
    value: companySortByOptions[3].value
  }
];

export const getDefaultSearchItems = (localisation?: OperatingCountry) => {
  if (localisation) {
    return getLocalisedDefaultSearchItems(localisation);
  }
  return defaultSearchItems;
};

export function getAssociatedStreamIDs(
  streams: CompanyProfile['companyStreams'],
  positionID: number
) {
  if (isEmpty(streams)) return [];
  return (
    streams.filter(({ listingIDs }) => listingIDs?.includes(positionID)).map(({ id }) => id) || []
  );
}

export function getSolelyAssociatedStreamIDs(
  streams: CompanyProfile['companyStreams'],
  positionID: number
) {
  if (isEmpty(streams)) return [];
  return (
    streams
      .filter(({ listingIDs }) => listingIDs?.length === 1 && listingIDs[0] === positionID)
      .map(({ id }) => id) || []
  );
}

export function getAssociatedArchivedPositions(
  positions: PositionInProfile[],
  companyUser = {},
  demoCompany?: boolean
) {
  if (isEmpty(positions)) return [];
  else if (demoCompany) {
    return (
      positions.filter(
        ({ status, postReviewStatus }) =>
          status === 'archived' || (postReviewStatus === 'archived' && status !== 'active')
      ) || []
    );
  } else {
    return (
      positions.filter(
        ({ members, status, postReviewStatus }) =>
          (members as $TSFixMe).indexOf((companyUser as $TSFixMe).accountID) !== -1 &&
          (status === 'archived' || (postReviewStatus === 'archived' && status !== 'active'))
      ) || []
    );
  }
}

export function getOtherLivePositions(
  positions: PositionInProfile[],
  companyUser = {},
  demoCompany?: boolean
) {
  if (isEmpty(positions) || demoCompany) return [];
  else {
    return (
      positions.filter(
        ({ members, status, postReviewStatus }) =>
          (members as $TSFixMe).indexOf((companyUser as $TSFixMe).accountID) === -1 &&
          (status === 'active' || (postReviewStatus === 'active' && status !== 'archived'))
      ) || []
    );
  }
}

export function getOtherArchivedPositions(
  positions: PositionInProfile[],
  companyUser = {},
  demoCompany?: boolean
) {
  if (isEmpty(positions) || demoCompany) return [];
  else {
    return (
      positions.filter(
        ({ members, status, postReviewStatus }) =>
          (members as $TSFixMe).indexOf((companyUser as $TSFixMe).accountID) === -1 &&
          (status === 'archived' || (postReviewStatus === 'archived' && status !== 'active'))
      ) || []
    );
  }
}

export function getNoAssociatedLivePositions(
  positions: PositionInProfile[],
  demoCompany?: boolean
) {
  if (isEmpty(positions) || demoCompany) return [];
  else {
    return positions.filter(
      ({ members, status, searchHidden }) =>
        isEmpty(members) && status === 'active' && !searchHidden
    );
  }
}

export function getLivePositionsAssociatedOnlyTo(
  userID: number,
  positions: PositionInProfile[],
  demoCompany?: boolean
) {
  if (isEmpty(positions) || demoCompany) return [];
  else {
    return positions.filter(
      ({ members, status }) => members.length === 1 && members[0] === userID && status === 'active'
    );
  }
}

function getAllLiveAndPendingApprovalPositions(positions: CompanyProfile['positions'] = []) {
  if (isEmpty(positions)) return [];
  return positions.filter(
    ({ status, postReviewStatus }) =>
      status === 'active' || (postReviewStatus === 'active' && status !== 'archived')
  );
}

export function getAllHiringSeats(users: CompanyAccountOutput[] = []) {
  if (isEmpty(users)) return [];
  return users.filter(
    ({ companyRole }) => companyRole === CompanyRole.Owner || companyRole === CompanyRole.FullAccess
  );
}

export function isPositionLimitReached(
  positions: CompanyProfile['positions'],
  subscriptionPlan: SubscriptionPlanInterface
) {
  const { positions: positionsInPlan } = subscriptionPlan?.configuration || {};
  const allLivePositions = getAllLiveAndPendingApprovalPositions(positions);

  return (
    allLivePositions.length >= (positionsInPlan?.limit || 9999) && !positionsInPlan?.unlimitedMode
  );
}

export function isUserLimitReached(
  users: CompanyAccountOutput[] | undefined = [],
  planConfiguration: SubscriptionPlanConfiguration | undefined
) {
  const { userSeats } = planConfiguration || {};
  const allHiringSeatsCount = getAllHiringSeats(users).length;

  return allHiringSeatsCount >= (userSeats?.limit || 9999) && !userSeats?.unlimitedMode;
}

export function getMessageStatus(
  companyArchived: $TSFixMe,
  companyHired: $TSFixMe,
  applicationStatus: $TSFixMe
) {
  if (companyArchived) return 'conversation_archived';
  else if (companyHired) return 'company_hired';
  return applicationStatus;
}

export function hasConversationStarted(applicationStatus: $TSFixMe) {
  return [
    'conversation_started',
    'company_accepted',
    'candidate_contacted',
    'company_contacted',
    'candidate_accepted',
    'company_hired'
  ].includes(applicationStatus);
}

export function isHiredAllowed(applicationStatus: $TSFixMe) {
  return ![
    'candidate_applied',
    'company_offered',
    'candidate_declined',
    'company_declined'
  ].includes(applicationStatus);
}

export function isArchiveAllowed(applicationStatus: $TSFixMe) {
  return !['candidate_applied', 'company_offered', 'conversation_archived'].includes(
    applicationStatus
  );
}

/**
 * @deprecated use isSubscriptionPlanExpired from v2/services
 **/
export function checkIfSubscriptionExpired(subscriptionPlan: string | undefined = ''): boolean {
  return !!(subscriptionPlan && new RegExp('_expired').test(subscriptionPlan));
}

export function isUnlimitedSourcingPeriod(planUsage?: UsageStatistics | null) {
  return !!(
    planUsage?.unlimitedCreditsExpiryDate &&
    new Date(planUsage?.unlimitedCreditsExpiryDate) > new Date()
  );
}

export function isLegacyPricingPlan(subscriptionPlan?: SubscriptionPlan) {
  return subscriptionPlan
    ? (consts.OLD_PRICING_OPTIONS as SubscriptionPlan[]).includes(subscriptionPlan)
    : false;
}

// It includes the New Pricing V1 & V2
export function isNewPricingPlan(subscriptionPlan?: SubscriptionPlan) {
  return subscriptionPlan
    ? (consts.ALL_NEW_PRICING_OPTIONS as SubscriptionPlan[]).includes(subscriptionPlan)
    : false;
}

export const isNewPricingPlanV1 = (subscriptionPlan?: SubscriptionPlan) => {
  return subscriptionPlan
    ? (consts.NEW_PRICING_OPTIONS_V1 as SubscriptionPlan[]).includes(subscriptionPlan)
    : false;
};

export const isNewPricingPlanV3 = (subscriptionPlan?: SubscriptionPlan) => {
  return subscriptionPlan
    ? (consts.V3_PRICING_OPTIONS as SubscriptionPlan[]).includes(subscriptionPlan)
    : false;
};

export const isNewPricingPlanV4 = (subscriptionPlan?: SubscriptionPlan) => {
  return subscriptionPlan
    ? (consts.V4_PRICING_OPTIONS as SubscriptionPlan[]).includes(subscriptionPlan)
    : false;
};

export const isUnlimitedPlan = (subscriptionPlan?: SubscriptionPlan) => {
  return subscriptionPlan ? UNLIMITED_PLANS.includes(subscriptionPlan) : false;
};

export const isCreditBasedPricing = (subscriptionPlan?: SubscriptionPlan) => {
  return (
    (isNewPricingPlan(subscriptionPlan) || isNewPricingPlanV3(subscriptionPlan)) &&
    !isUnlimitedPlan(subscriptionPlan)
  );
};

export const getPricingPlanDetails = (plan: SubscriptionPlan) => {
  switch (plan) {
    case NewPricingOptions.Starter:
      return {
        ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Starter],
        conversations: 10,
        users: 3
      };
    case NewPricingOptions.StarterV2:
      return {
        ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Starter],
        conversations: 15,
        users: 2
      };
    case NewPricingOptions.StarterV2Annual:
      return {
        ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Starter],
        conversations: 15 * 12,
        users: 2
      };
    case NewPricingOptions.Growth:
      return { ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Growth], conversations: 20 };
    case NewPricingOptions.GrowthV2:
      return { ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Growth], conversations: 25 };
    case NewPricingOptions.GrowthV2Annual:
      return {
        ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Growth],
        conversations: 25 * 12
      };
    case NewPricingOptions.GrowthPlus:
      return {
        ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Growth],
        label: 'Growth+',
        conversations: 30
      };
    case NewPricingOptions.GrowthPlusV2:
      return {
        ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Growth],
        label: 'Growth+',
        conversations: 35
      };
    case NewPricingOptions.GrowthPlusV2Annual:
      return {
        ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Growth],
        label: 'Growth+',
        conversations: 35 * 12
      };
    case NewPricingOptions.Scaling:
    case NewPricingOptions.ScalingV2:
      return {
        ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Scaling],
        conversations: 50
      };
    case NewPricingOptions.ScalingV2Annual:
      return {
        ...consts.PRICING_PLAN_COMMON_DETAILS[NewPricingOptions.Scaling],
        conversations: 50 * 12
      };
    case NewPricingOptions.Unlimited:
    case NewPricingOptions.UnlimitedV2:
      return {
        label: 'Unlimited',
        description: 'Built for established companies continually growing their teams.',
        conversations: 9999,
        positions: 9999,
        users: 9999
      };
    case CompanySubscriptionPlan.EarlyHires:
      return { label: 'Early Hires', description: '' };
    case CompanySubscriptionPlan.StartUp:
      return { label: 'Start-up & Scale-up', description: '' };
    case CompanySubscriptionPlan.Enterprise:
      return { label: 'Enterprise', description: '(unlimited users)' };
    case CompanySubscriptionPlan.EnterprisePosition:
      return { label: 'Enterprise', description: '(per hiring seat)' };
    case CompanySubscriptionPlan.Suspended:
      return { label: 'Suspended', description: '' };
    case CompanySubscriptionPlan.Expired:
      return { label: 'Expired', description: '' };
    case AgencySubscriptionPlans.V1:
      return { label: 'Agency', description: '' };
    default: {
      const isV3Pricing = isNewPricingPlanV3(plan);

      if (isV3Pricing || isNewPricingPlanV4(plan)) {
        const { growth, enterprise } = isV3Pricing ? getV3PricingOptions() : getV4PricingOptions();
        return isUnlimitedPlan(plan) ? enterprise : growth;
      }

      if (isNewPricingPlanV5(plan)) {
        // Not the ideal version to do this, but because the best option atm.
        let country = getLocalStorageItem<OperatingCountry | ''>('country');
        if (country !== OperatingCountry.US) country = OperatingCountry.UK;

        const { standard, enterprise } = consts.PRICING_V5_OPTIONS[country];
        return isUnlimitedPlan(plan) ? enterprise : standard;
      }

      return { label: '', description: '' };
    }
  }
};

export const getAvailableUpgradePlans = (currentPlan?: string) => {
  let upgradeOptions = consts.NEW_PRICING_OPTIONS_V2.slice(0, -1);

  if (!currentPlan) return upgradeOptions;

  // Check if the current plan is one of new pricing packages (V2)
  let currentPlanIndex = (consts.NEW_PRICING_OPTIONS_V2 as string[]).indexOf(currentPlan);

  if (currentPlanIndex === -1) {
    currentPlanIndex = (consts.NEW_PRICING_OPTIONS_V1 as string[]).indexOf(currentPlan);

    if (currentPlanIndex === -1) return upgradeOptions;
    upgradeOptions = consts.NEW_PRICING_OPTIONS_V1.slice(0, -1);
  }

  return upgradeOptions.slice(currentPlanIndex + 1);
};

export function getOpeningMsgLengthTooltip(messageLength = 0) {
  const ranges = [
    { min: 0, max: 299, color: errorColour, tooltip: 'Too short' },
    { min: 300, max: 600, color: activeDarkShade, tooltip: 'Inside recommended length' },
    { min: 601, max: 900, color: warningColour, tooltip: 'Over recommended length' },
    { min: 901, color: errorColour, tooltip: 'Too long' }
  ];
  const { color, tooltip } = ranges.find(
    ({ min, max }) => messageLength >= min && (!max || messageLength <= max)
  )!;

  return { color, tooltip };
}

export function getFollowupMsgLengthTooltip(messageLength = 0) {
  const ranges = [
    { min: 0, max: 49, color: errorColour, tooltip: 'Too short' },
    { min: 50, max: 400, color: activeDarkShade, tooltip: 'Inside recommended length' },
    { min: 401, color: errorColour, tooltip: 'Too long' }
  ];
  const characterCountRange = ranges.find(
    ({ min, max }) =>
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      (messageLength >= min && !max) || (messageLength >= min && messageLength <= max)
  );

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  return { color: characterCountRange.color, tooltip: characterCountRange.tooltip };
}

export function getJobDescriptionLengthTooltip(messageLength = 0) {
  const ranges = [
    { min: 0, max: 1999, color: errorColour, tooltip: 'Too short' },
    { min: 2000, max: 3500, color: activeDarkShade, tooltip: 'Inside recommended length' },
    { min: 3501, color: errorColour, tooltip: 'Too long' }
  ];
  const characterCountRange = ranges.find(
    ({ min, max }) =>
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      (messageLength >= min && !max) || (messageLength >= min && messageLength <= max)
  );

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  return { color: characterCountRange.color, tooltip: characterCountRange.tooltip };
}

export function getPrimarySkillFilters(streamParams: FeedParams) {
  return streamParams.params
    .filter(
      ({ attribute }) =>
        attribute === companySearchFilters.primarySkill.attribute ||
        attribute === companySearchFilters.orPrimarySkill.attribute
    )
    .map(({ label }) => label.replace('Core skill: ', ''));
}

export function storeDraftPosition(positionInfo: $TSFixMe) {
  const draftPosition = getLocalStorageItem('draft_position') || {};
  const duration = getMillisecondsByType('weeks');
  setLocalStorageItem('draft_position', { ...draftPosition, ...positionInfo }, duration);
}

export function storeDraftTemplate(templateInfo: $TSFixMe, type: $TSFixMe) {
  const draftTemplate = getLocalStorageItem(`draft_template_${type}`) || {};
  const duration = getMillisecondsByType('weeks');
  setLocalStorageItem(`draft_template_${type}`, { ...draftTemplate, ...templateInfo }, duration);
}

export function storeDraftMessage(message: $TSFixMe, type: $TSFixMe, id: $TSFixMe) {
  const duration = getMillisecondsByType('weeks');
  setLocalStorageItem(`draft_${type}_${id}`, message, duration);
}

export const SETUP_STEPS = [
  { to: '/company/setup/positions', label: 'Select positions' },
  { to: '/company/setup/complete-profile', label: 'Complete your profile' },
  { to: '/company/setup/review-profile', label: 'Review your profile' },
  { to: '/company/team', label: 'Invite your team' },
  { to: '/company/setup/finish', label: 'Finish' }
];

export const getInitialSetupSteps = (
  permissions: Permissions,
  profile: ProfileState
): {
  to: string;
  label: string;
  pathRegexs: RegExp[];
}[] => {
  if (!isCompanyProfile(profile)) return [];
  const isAgency = isAgencyProfile(profile);
  const { positions, isUnpaidAccount } = profile || {};
  const isPositionFromDemoPosted = !isEmpty(positions);

  return [
    {
      to: '/company/profile/edit',
      label: 'Build your company profile',
      pathRegexs: [/^\/company\/profile/]
    },
    { to: '/company/member/edit', label: 'Build your profile', pathRegexs: [/^\/company\/member/] },
    ...(permissions.atsIntegration && !isAgency
      ? [
          {
            to: '/company/setup/account/ats',
            label: 'Integrate your ATS',
            pathRegexs: [/^\/company\/setup\/account\/ats$/]
          }
        ]
      : []),
    ...(permissions.calendarIntegration && !isUnpaidAccount
      ? [
          {
            to: '/company/setup/account/calendar',
            label: 'Integrate your calendar',
            pathRegexs: [/^\/company\/setup\/account\/calendar$/]
          }
        ]
      : []),
    { to: '/company/team', label: 'Invite your team', pathRegexs: [/^\/company\/team$/] },
    {
      to: isPositionFromDemoPosted
        ? '/company/setup/account/positions'
        : '/company/create-position',
      label: 'Create your positions',
      pathRegexs: [
        /^\/company\/create-position$/,
        /^\/company\/setup\/account\/positions$/,
        /^\/company\/position\/[0-9]+\/(view|edit)$/
      ]
    },
    {
      to: '/company/setup/finish',
      label: 'Book your onboarding',
      pathRegexs: [/^\/company\/setup\/finish$/]
    }
  ];
};

export const INITIAL_SETUP_PAGE_TITLES = [
  { to: { pathname: /^\/company\/profile\/edit/ }, title: 'Edit Company Profile' },
  { to: { pathname: /^\/company\/profile/ }, title: 'Company Profile' },
  { to: { pathname: /^\/company\/member\/edit/ }, title: 'Build your profile' },
  { to: { pathname: /^\/company\/member/ }, title: 'Your Profile' },
  { to: { pathname: /^\/company\/setup\/account\/ats/ }, title: 'ATS Integration' },
  { to: { pathname: /^\/company\/setup\/account\/calendar/ }, title: 'Calendar Integration' },
  { to: { pathname: /^\/company\/team/ }, title: 'Invite your team' },
  { to: { pathname: /^\/company\/create-position/ }, title: 'Create a position' },
  { to: { pathname: /^\/company\/setup\/account\/positions/ }, title: 'Create your positions' },
  {
    to: { pathname: /^\/company\/position\/[0-9]+\// },
    title: [{ label: 'Create your positions', pathname: '/company/setup/account/positions' }]
  },
  { to: { pathname: /^\/company\/setup\/finish$/ }, title: 'Setup Complete' }
];

export function storeDraftCompanyProfile(profileInfo: $TSFixMe) {
  const draftProfile = getLocalStorageItem('draft_profile') || {};
  const duration = getMillisecondsByType('weeks');
  setLocalStorageItem('draft_profile', { ...draftProfile, ...profileInfo }, duration);
}

export const checkIfAccountIsSuspended = (
  plan: CompanySubscriptionPlan,
  suspensionStatus: CompanySuspensionStatus | undefined
) => {
  return (
    plan === CompanySubscriptionPlan.Suspended ||
    suspensionStatus === CompanySuspensionStatus.Enforced
  );
};

// Todo: example of why we need to consolidate localisation
export function getIsLocalisedPricingPage(pathname: string | undefined) {
  if (!pathname || !pathname.includes('/pricing')) return false;

  const urlLocale = pathname.match(/(u[ks])/i);
  return urlLocale?.[0];
}

export function getForCompaniesPageLocalisation(pathname: string | undefined) {
  if (!pathname || !pathname.includes('/companies')) return '';

  const urlLocale = pathname.match(/(u[ks])/i);
  return urlLocale?.[0]?.toUpperCase() || '';
}

const UNLIMITED_DEMO_LINK_PLANS: SubscriptionPlan[] = [
  NewPricingOptions.UnlimitedV2,
  V3PricingOptions.V3Unlimited
];

export function getDemoLink(plan?: SubscriptionPlan, from?: string) {
  if (from === '/founder-program') return consts.FOUNDERS_BOOK_DEMO_LINK;
  if (!plan) return consts.GENERIC_BOOK_DEMO_LINK;
  if (UNLIMITED_DEMO_LINK_PLANS.includes(plan)) return consts.UNLIMITED_BOOK_DEMO_LINK;
  if (plan === NewPricingOptions.ScalingV2) return consts.ENTERPRISE_BOOK_DEMO_LINK;
  if (plan === PayPerHirePricingOptions.PayPerHire) return consts.PAY_PER_HIRE_DEMO_LINK;
  return consts.GENERIC_BOOK_DEMO_LINK;
}

export const openArticleAndTrack = (article: string, locationInProduct: string) => {
  trackCompaniesHubLinkClicks({ articleID: article, locationInProduct });
  window.Helpkit.show('popover', 'article', article);
};

export function getPlanDesc(
  planDetails: PricingPlanDetails<NewPricingOptions>,
  options?: { brief?: boolean; showLabel?: boolean; includeExtraFeatures?: boolean }
) {
  const { label, conversations, positions, users, extraFeature } = planDetails;
  const { brief = true, showLabel = true, includeExtraFeatures } = options || {};

  let description = '';

  if (brief) {
    description = `${showLabel ? `${label} ` : ''}includes ${conversations} sourcing credit${
      conversations < 2 || 's'
    }, ${positions} active positions and ${users} user seats`;
  } else {
    description = `${
      showLabel ? `${label} ` : ''
    }includes up to ${conversations} sourcing credits/month, ${positions} active positions, ${users} user seats`;
  }

  if (!includeExtraFeatures) return `${description}.`;

  return `${description} and access to ${extraFeature}.`;
}

export function createStripeModule(region: StripeRegion) {
  const accessKey =
    region === StripeRegion.US
      ? process.env.REACT_APP_STRIPE_US_API_KEY
      : process.env.REACT_APP_STRIPE_UK_API_KEY;
  return window.Stripe(accessKey);
}

export const isExpiredOrSuspended = (profile: ProfileState) => {
  if (!isCompanyProfile(profile)) return false;

  const { companySuspension } = profile || {};
  const plan = getCurrentPlan(profile) as CompanySubscriptionPlan;
  const { status } = companySuspension || {};
  const isExpired = checkIfSubscriptionExpired(plan);
  const isSuspended = status && checkIfAccountIsSuspended(plan, status);
  if (isExpired || isSuspended) return true;

  return false;
};

export function getIsSourcingBlock(profile: ProfileState): boolean {
  if (!isCompanyProfile(profile)) return false;
  const { planUsage, payAsYouGo, subscriptionDetailsAndPricing } = profile || {};
  const { unlimitedMode } =
    subscriptionDetailsAndPricing?.subscriptionPlan?.configuration?.conversations || {};
  if (!planUsage || unlimitedMode) return false;

  if (isExpiredOrSuspended(profile)) return false;
  if (isUnlimitedSourcingPeriod(planUsage)) return false;

  const {
    credits: { limit, usage }
  } = planUsage;
  const limitHit = limit - usage <= 0;
  return !!limit && limitHit && !payAsYouGo;
}

export function getIsSourceWithoutBias(profile: CompanyProfile | null): boolean {
  const settings = profile?.companyUser?.preferences?.removeUnconsciousBias;
  const override = profile?.removeUnconsciousBiasOverride;

  return override || settings;
}

// WIP - After release: Move functions related to pricing to the pricingUtils.ts
export const getPricingComponent = (planInfo: {
  plan?: SubscriptionPlan;
  isToUpgrade?: boolean;
  isToReactivate?: boolean;
}) => {
  const { plan, isToUpgrade, isToReactivate } = planInfo;
  const isNewUser = !plan || plan === CompanySubscriptionPlan.Basic;

  if (isNewUser || isNewPricingPlanV5(plan) || isPayPerHirePricingPlan(plan)) {
    return {
      component: (props: Omit<PricingProps, 'pricingVer'>) => Pricing({ ...props, pricingVer: 5 }),
      title: 'Simple. Flexible. Affordable.'
    };
  }

  if (isNewPricingPlanV4(plan) || isToReactivate) {
    return { component: PricingPlansV4, title: 'Fair, flat and flexible' };
  }

  if (isLegacyPricingPlan(plan)) {
    return { component: PricingPlans, title: 'Fair, Flat and Flexible' };
  }

  if (isNewPricingPlan(plan) && isToUpgrade) {
    return {
      component: NewPricingPlans,
      title: "You've reached your plan limit"
    };
  }

  return { component: PricingPlansV3, title: 'Sourcing that scales' };
};

export const handleUpgradeCompletion = (plan: SubscriptionPlan, pathname: string) => {
  let confirmation: string;

  if (isNewPricingPlanV3(plan)) {
    const sourcingCredits = plan.slice(-2);

    confirmation = `Your upgrade to Growth (${sourcingCredits} sourcing credits) was successful.`;
  } else {
    const planUpgradeDetails = getPricingPlanDetails(plan) as PricingPlanDetails<NewPricingOptions>;
    const planDescription = getPlanDesc(planUpgradeDetails, {
      brief: false,
      includeExtraFeatures: true
    });

    confirmation = `You're now on the ${planUpgradeDetails.label} plan. ${planDescription}`;
  }

  setLocalStorageItem('planUpgradeComplete', confirmation);

  if (new RegExp(/^\/company\//).test(pathname)) window.location.reload();
  else window.location.href = '/company/discover';
};

export const setBookDemoTracking = async (event: 'click' | 'book', loggedIn?: boolean) => {
  if (loggedIn) {
    await setCompanyTracking(event === 'click' ? 'book-demo-click' : 'book-demo-complete');
  }
};

export const getSubscriptionInfo = (
  subscriptionDetailsAndPricing?: CompanyProfile['subscriptionDetailsAndPricing']
) => {
  if (!subscriptionDetailsAndPricing || isEmpty(subscriptionDetailsAndPricing)) return null;

  const {
    subscription: { pricingCountry },
    subscriptionPlan: { plan },
    pricing: { billingFrequency, mode }
  } = subscriptionDetailsAndPricing;

  return {
    currentPlanDetails: { plan: plan as SubscriptionPlan, billingFrequency, mode },
    pricingCountry,
    isSubscriptionExpired: checkIfSubscriptionExpired(plan)
  };
};
